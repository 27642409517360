body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.link-container {
  display: flex;
}

:root {
  --clr-neon: #00eeff;
  --clr-bg: #0e0e0e;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.App-link {
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  color: var(--clr-neon);
  border: var(--clr-neon) 2px solid;
  padding: 0.5em 1.5em;
  border-radius: 0.5em;
  position: relative;
  margin: 10px; /* Menambahkan margin sebesar 10px pada tombol */
}

.App-link::before {
  pointer-events: none;
  content: "";
  position: absolute;
  background: var(--clr-neon);
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  transform: perspective(1em) rotateX(40deg) scale(1, 0.35);
  filter: blur(1em);
  opacity: 0;
  transition: opacity 0.3s ease-in-out; /* Menambahkan efek transisi pada opacity */
}

.App-link:hover::before,
.App-link:focus::before {
  opacity: 0.7;
}

.App-link:hover,
.App-link:focus {
  color: var(--clr-bg);
  text-shadow: none;
  background-color: var(--clr-neon);
  box-shadow: none;
}

body {
  position: relative;
  min-height: 100vh;
}

.counter-container {
  position: absolute;
  bottom: 0;
  left: -25px;
  width: 100%;
  text-align: center;
  padding: 10px 0;
  z-index: 9999;
  background-color: transparent;
  border: none;
}


