.App {
  text-align: center;
}

.App-logo {
  /* animation: App-logo-spin infinite 20s linear; */
  height: 25vmin;
  margin-bottom: 50px;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 6000s linear;
  }
}

.App-header {
  position: relative;
  background-color: #212a3a00;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  z-index: 2; /* z-index yang lebih tinggi dari stars, twinkling, dan clouds */
}


.App-link {
  color: #61dafb;
}

.stars, .twinkling, .clouds {
	position:fixed;
	display:block;
	top:0; bottom:0;
	left:0; right:0;
	width:100%; height:100%;
}


.stars {
	z-index: 0;
	background: #000 url('https://i.imgur.com/YKY28eT.png') repeat top center;
}

.twinkling{
	z-index: 1;
	background:transparent url('https://i.imgur.com/XYMF4ca.png') repeat top center;
	animation: move-twink-back 200s linear infinite;
}

.clouds{
	z-index: 2;
    background:transparent url('https://i.imgur.com/mHbScrQ.png') repeat top center;
	animation: move-clouds-back 200s linear infinite;
}

@keyframes move-twink-back {
	from {background-position:0 0;}
	to {background-position:-10000px 5000px;}
}

@keyframes move-clouds-back {
	from {background-position:0 0;}
	to {background-position:10000px 0;}
}

.desktop-switch {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999;
}

#desktop-mode-toggle {
  background-color: white;
  border: 1px solid black;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .desktop-switch {
    display: none;
  }
}
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@100;500&display=swap');

.string {
  font-family: JetBrains Mono, monospace;
}
